'use client';

import { useParams } from 'next/navigation';
import { useState } from 'react';

import { unlockCpPornAlbumEpisodeVideo } from '@/api/couple/cpInfo';
import CpMediaInfoCard from '@/components/cp/cpMediaInfoCard/cpMediaInfoCard';
import Footer from '@/features/footer/footer';
import Header from '@/features/header/header';
import { useDepositStore } from '@/store/deposit';
import { useNotifyStore } from '@/store/notify';
import { CpPornAlbumEpisodeVideo, CpPornAlbumInfo, CpInfoType } from '@/types/cpType';

type ParamsType = {
    userId: string;
    albumId: string;
    episodeId: string;
};

function VideoInfo({
    userInfo,
    videoInfo,
    albumInfo,
    onClose,
    isScrolled
}: {
    userInfo: CpInfoType;
    videoInfo: CpPornAlbumEpisodeVideo;
    albumInfo: CpPornAlbumInfo;
    onClose?: () => void;
    isScrolled?: boolean;
}) {
    const [videoData, setVideoData] = useState<CpPornAlbumEpisodeVideo>({ ...videoInfo, isMuted: true });
    const params: ParamsType = useParams();
    const setSnackMessage = useNotifyStore.use.setSnackMessage();
    const setDepositPopup = useDepositStore.use.setDepositPopup();

    const handleUnLock = async () => {
        const res = await unlockCpPornAlbumEpisodeVideo({
            userId: userInfo.user_id || params.userId,
            albumId: albumInfo.id || params.albumId,
            episodeId: videoInfo.id || params.episodeId
        });

        if (res.status === 200) {
            setSnackMessage({ message: '解鎖成功' });
            setVideoData(res.data);
        } else {
            setSnackMessage({ message: res.errorMsg });
            if (res.errorMsg === '鑽石不足') {
                setDepositPopup();
            }
        }
    };

    const handleMuted = () => {
        setVideoData((preVideo) => {
            return {
                ...preVideo,
                isMuted: !preVideo.isMuted
            }
        })
    }

    return (
        <>
            <Header />
            <CpMediaInfoCard
                onClose={onClose}
                userInfo={userInfo}
                mediaData={videoData}
                isVideo
                isPorn
                albumInfo={albumInfo}
                handleUnLock={handleUnLock}
                handleMuted={handleMuted}
                isScrolled={isScrolled}
            />
            <Footer />
        </>
    );
}

export default VideoInfo;
