'use client';
import { usePathname, useRouter } from 'next/navigation';
import { useEffect, useState } from 'react';

import BaseLink from '@/components/baseLink/baseLink';
import { useUserInfoCompletedHook } from '@/features/userInfoCompleted/userInfoCompleted';
import { useBaseStore } from '@/store/base';
import { useChatStore } from '@/store/chat';
import { useCpMediaStore } from '@/store/cpMedia';
import { useUserStore } from '@/store/user';

import Chat from './img/chat.svg';
import ChatDefault from './img/chatDefault.svg';
import LogoIcon from './img/logo.svg';
import LogoDefault from './img/logoDefault.svg';
import Personal from './img/personal.svg';
import PersonalDefault from './img/personalDefault.svg';
import VideoFeed from './img/videoFeed.svg';
import VideoFeedDefault from './img/videoFeedDefault.svg';

import style from './footer.module.scss';

type Props = {
    ulClassName?: string;
}

function Footer({ ulClassName }: Props) {
    const pathname = usePathname();
    const loginPopup = useBaseStore.use.loginPopup();
    const setLoginPopup = useBaseStore.use.setLoginPopup();
    const isLogin = useUserStore.use.isLogin();
    const router = useRouter();
    const close = useCpMediaStore.use.close();

    const [activeTab, setActiveTab] = useState(pathname);

    const { checkUserInfoCompleted } = useUserInfoCompletedHook();

    useEffect(() => {
        router.prefetch('/');
        router.prefetch('/chatroom');
        router.prefetch('/myInformation');
        router.prefetch('/videoFeed');
    }, []);

    useEffect(() => {
        if (pathname !== activeTab) {
            setActiveTab(pathname);
        }
    }, [pathname]);

    const toChatRoom = () => {
        if (checkUserInfoCompleted()) {
            return;
        }
        setActiveTab('/chatroom');
        router.push('/chatroom');
        close();
    };

    const unreadCount = useChatStore.use.chatUserUnread();

    return (
        <footer>
            <nav className={`footer ${style.footer}`}>
                <ul className={`${style.ul} ${ulClassName}`}>
                    <li className={style.li}>
                        {isLogin ? (
                            <BaseLink
                                href="/chatroom"
                                className={style.link}
                                noRouter
                                onClick={toChatRoom}
                            >
                                {activeTab.includes('chat') ? (
                                    <Chat className={style.icon} />
                                ) : (
                                    <ChatDefault className={style.icon} />
                                )}
                                <div
                                    className={`${style.text} ${activeTab.includes('chat') ? style.active : ''
                                        }`}
                                >
                                    聊天
                                    {unreadCount > 0 && <div className={style.pointer}>N+</div>}
                                </div>
                            </BaseLink>
                        ) : (
                            <div className={style.link} onClick={() => setLoginPopup()}>
                                <ChatDefault className={style.icon} />
                                <div className={style.text}>聊天</div>
                            </div>
                        )}
                    </li>
                    <li className={style.li}>
                        <BaseLink className={style.link} href="/" onClick={() => setActiveTab('/')}>
                            {activeTab === '/' ? (
                                <LogoIcon className={style.icon} />
                            ) : (
                                <LogoDefault className={style.icon} />
                            )}
                            <div
                                className={`${style.text} ${activeTab === '/' ? style.active : ''}`}
                            >
                                探索
                            </div>
                        </BaseLink>
                    </li>
                    <li className={style.li}>
                        <BaseLink className={style.link} href="/videoFeed" onClick={() => setActiveTab('/videoFeed')}>

                            {activeTab.includes('/videoFeed') ? (
                                <VideoFeed className={style.icon} />
                            ) : (
                                <VideoFeedDefault className={style.icon} />
                            )}
                            <div
                                className={`${style.text} ${activeTab === '/videoFeed' ? style.active : ''}`}
                            >
                                速尻
                            </div>
                        </BaseLink>
                    </li>
                    {isLogin ? (
                        <li className={style.li}>
                            <BaseLink
                                className={style.link}
                                href="/myInformation"
                                onClick={() => {
                                    setActiveTab('/myInformation');
                                    close();
                                }}
                            >
                                {activeTab.includes('/myInformation') ? (
                                    <Personal className={style.icon} />
                                ) : (
                                    <PersonalDefault className={style.icon} />
                                )}
                                <div
                                    className={`${style.text} ${activeTab.includes('/myInformation') ? style.active : ''
                                        }`}
                                >
                                    我的
                                </div>
                            </BaseLink>
                        </li>
                    ) : (
                        <li className={style.li} onClick={() => setLoginPopup()}>
                            {loginPopup ? (
                                <Personal className={style.icon} />
                            ) : (
                                <PersonalDefault className={style.icon} />
                            )}
                            <div className={style.text}>登入</div>
                        </li>
                    )}
                </ul>
            </nav>
        </footer>
    );
}

export default Footer;
