import Image from 'next/image';
import Link from 'next/link';

import { useUserInfoCompletedHook } from '@/features/userInfoCompleted/userInfoCompleted';
import { useValidatePhoneHook } from '@/features/validatePhone/validatePhone';
import { useBaseStore } from '@/store/base';
import { useCpMediaStore } from '@/store/cpMedia';
import { useDepositStore } from '@/store/deposit';
import { useUserStore } from '@/store/user';

import Diamond from './img/diamond.png';
import Fav from './img/fav.svg';
import Logo from './img/logo.png';
import NoticeIcon from './img/notice.svg';
import SearchIcon from './img/search.svg';
import Notice from './notice';
import Search from './search';

import style from './header.module.scss';

function Favorite() {
    return (
        <Link href="/myInformation/subscribe">
            <Fav />
        </Link>
    );
}

function Header() {
    const setDepositPopup = useDepositStore.use.setDepositPopup();
    const { checkUserInfoCompleted } = useUserInfoCompletedHook();
    const { checkValidatePhone } = useValidatePhoneHook();
    const isLogin = useUserStore.use.isLogin();
    const setLoginPopup = useBaseStore.use.setLoginPopup();
    const setOpenSearch = useCpMediaStore.use.setOpenSearch();

    const rechargeHandler = () => {
        if (isLogin) {
            if (checkUserInfoCompleted() || checkValidatePhone()) {
                return;
            }
            setDepositPopup();
        } else {
            setDepositPopup();
        }
    };

    return (
        <>
            <header className={`header ${style.header}`}>
                <div className={style.fixed}>
                    <div className={style.logo} onClick={() => window.location.replace('/')}>
                        <Image src={Logo} height={24} width={108} priority alt="why壞壞" />
                    </div>
                    <div className={style.tool}>
                        <SearchIcon className={style.searchIcon} onClick={() => setOpenSearch(true)} />
                        {isLogin ? <Favorite /> : <Fav onClick={setLoginPopup} />}
                        {isLogin ? <Notice /> : <NoticeIcon onClick={setLoginPopup} />}
                        <Image
                            src={Diamond}
                            width={24}
                            height={24}
                            alt="diamond"
                            priority
                            onClick={rechargeHandler}
                        />
                    </div>
                </div>
            </header>
            <Search />
        </>
    );
}

export default Header;
